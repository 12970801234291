import '@/styles/styles.scss'
import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

axios.defaults.baseURL = 'https://lk.xbadm.ru/api/v1'
// axios.defaults.baseURL = 'https://www.lk.xbadm.khimkibcdev.tech/api/v1'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1'

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
