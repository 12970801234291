<template>
  <div>
    <div class="align-center"></div>

    <div v-if="feedback">
      <p class="text-2xl mb-6">
        Обратная связь клиента: {{ feedback.client_name }}
      </p>

      <div class="mb-5">
        <router-link to="/feedback">
          Назад к списку отзывов
        </router-link>
      </div>

      <v-card>
        <v-card-text>
          <strong>Данные тренировки</strong> <br>
          {{ feedback.training_title }} <br>
          {{ feedback.training_time | formatDate }} <br>
          Тренер: {{ feedback.staff_name }}
        </v-card-text>

        <v-card-text>
          <strong>Данные клиента</strong><br>
          {{ feedback.client_name }} <br>
          {{ feedback.client_phone }}
        </v-card-text>

        <v-card-title>
          <strong>Ответ</strong>
        </v-card-title>
        <template v-if="feedback.rating">
          <v-card-text>
            <div class="mb-5">
              {{ feedback.text }}
            </div>
            <template>
              <span
                v-for="(n,index) in feedback.rating"
                :key="index"
              >
                <v-icon
                  color="success"
                >
                  {{ icons.mdiStar }}
                </v-icon>
              </span>
            </template>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text>
            <i>Ожидаем ответ от клиента</i>
          </v-card-text>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script>
import {
  mdiStar,
} from '@mdi/js'

import Api from '@/api/api'

export default {
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate(value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} ${hour}:${minute === 0 ? '00' : minute}`
    },
  },
  data() {
    return {
      loading: false,
      feedback: null,
      icons: {
        mdiStar,
      },
    }
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getFeedback()
  },
  methods: {
    getFeedback() {
      this.loading = true
      const params = new URLSearchParams([['read', parseInt('1')]])
      try {
        Api.getUsersFeedbackByUuid(this.$route.params.uuid, { params }).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'feedback', event)
          }),
        )
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}
.unread{
  font-weight: bold;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
