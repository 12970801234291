<template>
  <div v-if="reservation">
    <v-btn
      :to="'/events/' + $route.params.id"
      class="ma-0 mb-5"
      transparent
    >
      Назад
    </v-btn>
    <v-btn
      :to="'/events/' + $route.params.id + '/' + $route.params.reservation_id + '/edit'"
      class="ma-0 mb-5"
      transparent
      color="success ml-5"
    >
      Редактировать
    </v-btn>
    <v-btn
      class="ma-0 mb-5"
      transparent
      color="error ml-5"
      @click="confirmDelete(front_reservation.uuid)"
    >
      Удалить
    </v-btn>
    <p class="text-2xl mb-6">
      Бронирование №{{ reservation.order_id }}
      <v-btn
        v-if="!reservation.confirm"
        small
        transparent
        @click="confirmReservation(front_reservation.reservation_uuid)"
      >
        Подвердить оказание услуги
      </v-btn>
      <v-chip
        v-else
        class="ma-2"
        small
        color="success"
      >
        Услуга оказана
      </v-chip>
    </p>
    <v-row>
      <v-col
        cols="12"
        class="mb-12"
        xs="12"
        sm="12"
        md="12"
        lg="7"
      >
        <v-card class="overflow-hidden mb-5">
          <v-card-title
            class="mt-3"
            style="font-size: 20px; font-weigth: bold"
          >
            Контакты клиента
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-text>
            <ul class="client_data">
              <li>Имя: {{ reservation.client.name }}</li>
              <li>Email: {{ reservation.client.email }}</li>
              <li>Телефон: {{ reservation.client.phone }}</li>
              <li>Бонусные баллы: {{ reservation.client.bonuspoint }}</li>
            </ul>
          </v-card-text>
        </v-card>
        <v-card class="overflow-hidden">
          <v-row class="ma-0 h-full">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <v-card-title
                class="mt-2"
                style="font-size: 25px; font-weigth: bold"
              >
                {{ reservation.header }}
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <div
                v-for="room in reservation.suits"
                :key="room.id"
              >
                <v-card-text class="d-flex">
                  <div v-show="room.image">
                    <v-avatar
                      rounded
                      size="120"
                    >
                      <v-img :src="'https://lk.xbadm.ru' + room.image"></v-img>
                    </v-avatar>
                  </div>
                  <div>
                    <v-card-title style="font-size: 16px">
                      {{ room.name }} №{{ room.room_number }}
                    </v-card-title>
                    <v-card-subtitle class="text-xs pb-0">
                      {{ room.options.package }} <br />
                      {{ room.available_room_type[0] }}
                    </v-card-subtitle>
                  </div>
                </v-card-text>
                <v-card-text>
                  <v-divider></v-divider>
                </v-card-text>
                <div
                  v-for="participant in room.participants"
                  :key="participant.id"
                >
                  <div>
                    <v-card-text>
                      <h2 class="font-weight-medium">
                        {{ participant.name }}
                      </h2>
                      <div>Даты пребывания: {{ participant.start }} - {{ participant.end }}</div>
                      <span class="text-xs text-no-wrap">{{ participant.age }}</span>
                      <span
                        v-if="participant.childAge"
                        class="text-xs text-no-wrap"
                      >, {{ participant.childAge }}
                        лет</span>
                      <span class="text-xs text-no-wrap">, {{ participant.participantType }}</span>
                    </v-card-text>
                  </div>
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div v-if="participant.price.base_price == participant.price.price">
                        Цена
                      </div>
                      <div v-else>
                        Базовая стоимость
                      </div>
                      <div class="font-weight-semibold">
                        {{ participant.price.base_price | format }} ₽
                      </div>
                    </div>

                    <div
                      v-if="participant.price.discount.discount > 0"
                      class="d-flex justify-space-between"
                    >
                      <div v-if="participant.price.discount">
                        {{ participant.price.discount.name }}
                      </div>
                      <div v-else>
                        Скидка
                      </div>
                      <div class="font-weight-semibold success--text">
                        - {{ participant.price.discount.discount | format }} ₽
                      </div>
                    </div>
                    <div
                      v-if="participant.price.overprice.overprice > 0"
                      class="d-flex justify-space-between"
                    >
                      <div>{{ participant.price.overprice.name }}</div>
                      <div class="font-weight-semibold warning--text">
                        + {{ participant.price.overprice.overprice | format }} ₽
                      </div>
                    </div>
                    <div
                      v-if="participant.insurance"
                      class="d-flex justify-space-between"
                    >
                      <div>Страховка</div>
                      <div class="font-weight-semibold warning--text">
                        + {{ participant.price.insurance | format }} ₽
                      </div>
                    </div>
                    <div
                      v-if="participant.price.base_price != participant.price.price"
                      class="d-flex justify-space-between"
                    >
                      <div>Цена</div>
                      <div class="font-weight-semibold">
                        {{ participant.price.price | format }} ₽
                      </div>
                    </div>

                    <v-divider class="mt-5"></v-divider>
                  </v-card-text>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <ul class="list_small_data mt-3">
          <li>Дата создания: {{ reservation.date }}</li>
          <li>Последнее обновление: {{ reservation.edit }}</li>
        </ul>
      </v-col>
      <v-col
        cols="12"
        class="mb-12"
        xs="12"
        sm="12"
        md="12"
        lg="5"
      >
        <v-card class="overflow-hidden">
          <v-row class="ma-0 h-full">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <div
                v-for="room in reservation.suits"
                :key="room.id"
                class="summary_block"
              >
                <v-card-title style="font-size: 16px;">
                  {{ room.name }} №{{ room.room_number }}
                </v-card-title>

                <v-card-text class="aling_right">
                  <div>
                    Базовая стоимость: <span class="font-weight-semibold">{{ room.price | format }} ₽</span>
                  </div>
                </v-card-text>
              </div>
              <div>
                <v-card-text class="aling_right">
                  <div class="list_payment_final">
                    <div>Итого: <span class="font-weight-semibold">{{ reservation.pay_price | format }} ₽</span></div>
                    <div v-if="reservation.discount">
                      <span style="text-decoration:line-through;">{{ reservation.price | format }}₽</span>
                      <v-chip
                        class="ml-2"
                        small
                        color="success"
                      >
                        {{ reservation.discount.name }} %
                      </v-chip>
                    </div>
                    <div v-if="reservation.discount">
                      <ul class="discount-list">
                        <li v-for="discount in reservation.discount.list">
                          <small>{{ discount.text }}</small>
                          <span class="summary-price">{{ discount.name }}%</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="list_payment_final">
                    <span
                      v-if="reservation.pay_price"
                      class="font-weight-semibold"
                    >
                      <span class="font-weight-semibold success--text">Оплачено: {{ reservation.pay_sum | format }}
                        ₽</span>
                    </span>
                    <span
                      v-else
                      class="font-weight-semibold"
                    >Оплачено: 0 ₽ </span>

                    <div v-if="reservation.pay_sum > reservation.pay_price">
                      <span class="font-weight-semibold error--text">
                        <span>Переплата: {{ reservation.pay_sum - reservation.pay_price | format }} ₽</span>
                      </span>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text>
                  <v-divider></v-divider>
                </v-card-text>

                <v-card-title>Счета</v-card-title>
                <div
                  v-for="invoice in reservation.invoices"
                  :key="invoice.id"
                  class="summary_block"
                >
                  <div style="position: relative">
                    <v-card-title style="font-size: 15px">
                      Номер: {{ invoice.payment_data.order }}
                    </v-card-title>
                    <!-- <v-card-subtitle class="text-xs pb-0">
                      {{ invoice.uuid_group }}
                    </v-card-subtitle> -->
                    <div class="">
                      <div>
                        <v-card-text class="center">
                          Сумма счета: {{ invoice.price | format }} ₽
                        </v-card-text>
                      </div>
                      <div>
                        <v-card-text v-if="invoice.pay">
                          <v-chip
                            v-if="invoice.payment_type == 1"
                            class="ma-2"
                            small
                            color=""
                          >
                            Входящий
                          </v-chip>
                          <v-chip
                            v-else
                            class="ma-2"
                            small
                            color=""
                          >
                            Исходящий
                          </v-chip>
                          <v-chip
                            class="ma-2"
                            small
                            color=""
                          >
                            {{ payment_method_type[invoice.payment_method] }}
                          </v-chip>
                          <v-chip
                            class="ma-2"
                            small
                            color="success"
                          >
                            Оплачен
                          </v-chip>
                        </v-card-text>
                        <v-card-text v-else>
                          <v-chip
                            class="ma-2"
                            small
                            color="error"
                          >
                            Неоплачен
                          </v-chip>
                        </v-card-text>
                      </div>
                    </div>
                    <v-btn
                      v-show="!invoice.pay || invoice.cashbox == 2"
                      color="error mb-5 ml-5"
                      small
                      @click="deleteInvoice(invoice.id)"
                    >
                      Удалить
                    </v-btn>
                    <v-btn
                      color="success mb-5 ml-5"
                      small
                      @click="checkPay(invoice.id)"
                    >
                      Проверить оплату
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          class="ma-0 mb-5 mt-5"
          transparent
          color="success ml-5"
          @click="invoice_modal = true"
        >
          Создать счёт
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="invoice_modal"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Создать новый счет
        </v-card-title>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-title class="pb-0 mb-0 mt-0 pt-0">
          Тип счета
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="invoice_data.payment_type">
            <v-radio
              :key="1"
              label="Входящий"
              :value="1"
            ></v-radio>
            <v-radio
              :key="2"
              label="Исходящий"
              :value="2"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-title class="pb-0 mb-0 mt-0 pt-0">
          Выберете кассу
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="invoice_data.cashbox">
            <v-radio
              :key="2"
              label="Наличная касса"
              :value="2"
            ></v-radio>
            <v-radio
              :key="1"
              label="Безналичная касса"
              :value="1"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="invoice_data.name"
            outlined
            hide-details
            label="Наименование услуги"
            required
          >
          </v-text-field>
        </v-card-text>
        <v-card-text>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model.number="invoice_data.price"
            outlined
            hide-details
            type="number"
            label="Сумма счета"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model.number="invoice_data.comment"
            outlined
            hide-details
            type="text"
            label="Комментарий"
            required
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-checkbox
            v-show="invoice_data.cashbox == 2"
            v-model="invoice_data.status"
            label="Оплачен"
            color="success"
          >
          </v-checkbox>
          <v-checkbox
            v-model="invoice_data.warning"
            label="Обратить внимание на счет"
            color="success"
          ></v-checkbox>
        </v-card-text>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click="createCashInvoice"
          >
            Создать
          </v-btn>
          <v-btn
            color=""
            @click="invoice_modal = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="delete_reservation_dialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Подтвердите удаление бронирования
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="delete_confirm"
            label="Для удаления напишите УДАЛИТЬ"
            required
          ></v-text-field>
          <span
            v-show="delete_error"
            style="color: red"
          >{{ delete_error }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="; (delete_reservation_dialog = false), (delete_uuid = null)">
            Отменить
          </v-btn>
          <v-btn
            color="error darken-1"
            @click=" deleteResesrvation(delete_uuid)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  components: {},
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
  },
  data() {
    return {
      invoice_data: {
        payment_type: 1,
        name: 'Оказание комплекса услуг по физической культуре и спорту',
        cashbox: 2,
        price: null,
        status: 0,
      },
      reservation: null,
      front_reservation: null,
      invoice_modal: false,
      delete_reservation_dialog: false,
      delete_confirm: null,
      delete_error: null,
      delete_uuid: null,
      payment_method_type: {
        1: 'Картой',
        2: 'Наличными',
        3: 'Личный счет',
      },
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
    )
  },

  // setup() {
  //   const reservation = null
  //   const front_reservation = null

  //   const invoice_modal = false

  //   return {
  //     reservation,
  //     front_reservation,
  //     invoice_modal,
  //   }
  // },
  async mounted() {
    await this.getFrontReservation()
    await this.getReservation()
  },
  methods: {
    async getReservation() {
      try {
        Api.getReservationByUuid({
          reservation_uuid: this.$route.params.reservation_id,
          event_id: this.$route.params.id,
        }).then(
          (event => {
            this.$set(this, 'reservation', event)
          }),
        )
      } catch { }
    },
    async getFrontReservation() {
      try {
        Api.getReservation({
          reservation_uuid: this.$route.params.reservation_id,
        }).then(
          (event => {
            this.$set(this, 'front_reservation', event)
          }),
        )
      } catch { }
    },
    async deleteInvoice(invoice_id) {
      try {
        Api.deleteInvoice({
          invoice_id,
        }).then(
          (event => {
            this.getFrontReservation()
            this.getReservation()
          }),
        )
      } catch { }
    },
    async checkPay(invoice_id) {
      try {
        Api.checkPay({
          invoice_id,
        }).then(
          (event => {
            if (event.Payments) {
              alert(`Статус: ${event.Payments[0].Status} Сумма: ${event.Payments[0].Amount / 100}`)
            } else {
              alert(event.Message)
            }
          }),
        )
      } catch { }
    },
    async createCashInvoice() {
      try {
        Api.createCashInvoice({
          reservation_uuid: this.front_reservation.reservation_uuid,
          invoice_data: this.invoice_data,
        }).then(
          (event => {
            this.getReservation()
          }),
        )
      } catch { }
    },
    confirmDelete(uuid) {
      this.delete_uuid = uuid
      this.delete_reservation_dialog = true
    },
    async deleteResesrvation(uuid) {
      if (this.delete_confirm == 'УДАЛИТЬ') {
        this.delete_error = null
        this.delete_reservation_dialog = false
        try {
          Api.deleteReservation({
            reservation_uuid: uuid,
          }).then(
            (event => {
              this.$router.push({
                path: `/events/${this.$route.params.id}`,
                replace: true,
              })
            }),
          )
        } catch { }
      } else {
        this.delete_error = 'подтвердите удаление'
      }
    },
    async confirmReservation(uuid) {
      Api.confirmReservation({
        reservation_uuid: uuid,
      }).then(
        (event => {
          this.getReservation()
        }),
      )
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}

.summary_block {
  background: #f6f7f9;
  border-radius: 10px;
  margin: 10px 10px 10px;
}

.list_payment {
  margin: 2px 0;
}

.list_payment_final {
  margin: 2px 0;
  font-size: 16px;
}

.v-card .v-card__title {
  line-height: inherit;
}

.client_data {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.list_small_data {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 11px;
}

.aling_right {
  text-align: right;
}

.discount-list {
  margin: 10px 0 15px 0;
  padding: 0;
  list-style: none;

  li {
    align-items: center;
    line-height: 1.5;

    &:not(:last-child):not(:only-child) {
      border-bottom: 1px solid #e7e7e7;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
    }

    small {
      margin-right: 20px;
    }
  }

  .summary-price {
    font-size: 13px;
    font-weight: bold;
  }
}
</style>
