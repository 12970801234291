<template>
  <div v-if="reservation_object">
    <v-btn
      :to="'/events/' + $route.params.id + '/' + $route.params.reservation_id"
      class="ma-0 mb-5"
      transparent
    >
      Назад
    </v-btn>
    <add-room-modal
      title="+ Добавить номер"
      @add-reservation="AddReservation"
    ></add-room-modal>

    <v-row v-if="reservation_object.reservation">
      <v-col
        cols="12"
        class="mb-6"
        xs="12"
        sm="12"
        md="12"
        lg="7"
      >
        <v-card class="overflow-hidden mb-5">
          <v-row class="ma-0 h-full">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <v-card-title
                class="mt-2"
                style="font-size: 20px; font-weigth: bold"
              >
                {{ reservation_object.reservation.header }}
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card class="mb-5">
          <v-card-title>Укажите пользователя</v-card-title>
          <v-card-text>
            <autocomplete @updateParent="updateSelected" style="margin-bottom: 20px;"></autocomplete>
          </v-card-text>
        </v-card> -->
        <v-card
          v-for="room in reservation_object.reservation.suits"
          :key="room.id"
          class="overflow-hidden mb-10"
        >
          <v-row class="ma-0 h-full pt-5">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <div>
                <v-card-text class="d-flex align-center">
                  <div v-show="room.image">
                    <v-avatar
                      rounded
                      size="120"
                    >
                      <v-img :src="'https://lk.xbadm.ru' + room.image"></v-img>
                    </v-avatar>
                  </div>
                  <div>
                    <v-card-title> {{ room.name }} №{{ room.room_number }}</v-card-title>

                    <v-card-subtitle class="text-xs pb-0">
                      {{ room.options.package }} <br />
                      {{ room.room_available_type[0] }}
                    </v-card-subtitle>
                    <v-card-text class="mt-5">
                      <v-btn
                        x-small
                        depressed
                        color="primary"
                        class="mr-3"
                        @click="selectChangeRoom(room.id, room.reservation_room_id)"
                      >
                        Заменить номер
                      </v-btn>
                      <v-btn
                        x-small
                        depressed
                        color="error"
                        @click="deleteRoom(reservation_object.uuid, room.id)"
                      >
                        Удалить номер
                      </v-btn>
                    </v-card-text>
                  </div>
                </v-card-text>
                <v-card-text>
                  <v-divider></v-divider>
                </v-card-text>

                <div>
                  <v-card-title class="pb-0 mb-0">
                    Тип номера
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                      v-model="room.options.type"
                      mandatory
                    >
                      <v-radio
                        v-for="(t, key) in room.room_available_type"
                        :key="t"
                        :label="t"
                        :value="key"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </div>

                <div v-show="room.options.type === '1'">
                  <v-card-title class="pb-0 mb-0">
                    Пол участника
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                      v-model="room.options.gender"
                      mandatory
                    >
                      <v-radio
                        v-for="(g, key) in room.available_gender"
                        :key="g"
                        :label="g"
                        :value="key"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </div>

                <v-card-text>
                  <v-divider></v-divider>
                </v-card-text>

                <div
                  v-for="(participant, index) in room.participants"
                  :key="participant.index"
                >
                  <v-card-title>
                    <span>Данные {{ index + 1 }}-го участника </span>
                    <v-spacer></v-spacer>
                    <v-btn
                      x-small
                      color="error"
                      @click="deletePeople(room.participants, index)"
                    >
                      Удалить
                    </v-btn>
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="participant.name"
                      outlined
                      hide-details
                      label="Фамилия Имя"
                      required
                    >
                    </v-text-field>

                    <v-switch
                      v-model="participant.show_in_list"
                      label="Показывать учатстника в общем списке участников"
                    ></v-switch>

                    <div
                      class="d-flex"
                      style="margin-bottom: 30px;"
                    >
                      <div>
                        <div class="label">
                          Дата заезда
                        </div>
                        <date-picker
                          v-model="participant.start"
                          :lang="lang"
                          type="date"
                          value-type="format"
                          class="v-input__control mr-md-4 mr-sm-4"
                          :format="'YYYY-MM-DD'"
                        ></date-picker>
                      </div>
                      <div>
                        <div class="label">
                          Дата выезда
                        </div>
                        <date-picker
                          v-model="participant.end"
                          :lang="lang"
                          type="date"
                          value-type="format"
                          class="v-input__control mr-md-4 mr-sm-4"
                          :format="'YYYY-MM-DD'"
                        ></date-picker>
                      </div>
                    </div>

                    <label for="participantType">Тип Питания</label>
                    <v-radio-group
                      id="participantType"
                      v-model="participant.food"
                      mandatory
                    >
                      <v-radio
                        label="Стандартное"
                        value="Стандартное"
                      ></v-radio>
                      <v-radio
                        label="Вегетарианское"
                        value="Вегетарианское"
                      ></v-radio>
                    </v-radio-group>

                    <label for="participantType">Тип участника</label>
                    <v-radio-group
                      id="participantType"
                      v-model="participant.participantType"
                      mandatory
                    >
                      <v-radio
                        label="Спортсмен"
                        value="Спортсмен"
                      ></v-radio>
                      <v-radio
                        label="Сопровождающий"
                        value="Сопровождающий"
                      ></v-radio>
                    </v-radio-group>

                    <label for="participantType">Возраст участника</label>
                    <v-radio-group
                      id="participantType"
                      v-model="participant.age"
                      mandatory
                    >
                      <v-radio
                        label="Взрослый"
                        value="Взрослый"
                      ></v-radio>
                      <v-radio
                        label="Ребенок (до 13 лет включительно)"
                        value="Ребенок (до 13 лет включительно)"
                      >
                      </v-radio>
                    </v-radio-group>

                    <v-text-field
                      v-show="participant.age != 'Взрослый'"
                      v-model="participant.childAge"
                      outlined
                      hide-details
                      label="Полных лет на дату заезда"
                      class="v-label_small"
                    >
                    </v-text-field>

                    <v-checkbox
                      v-model="participant.insurance"
                      hide-details
                      label="Добавить страховку от невыезда"
                    >
                    </v-checkbox>
                    <v-text-field
                      v-model="participant.discount_admin"
                      outlined
                      hide-details
                      label="Скидка"
                      class="v-label_small mt-5"
                      required
                    ></v-text-field>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >Дополнительная скидка участника <v-icon>{{ icons.mdiHelpCircle
                        }}</v-icon></span>
                      </template>
                      <span>Пример: Если система автоматически выдала клиенту скидку 10% <br> а в поле дополнительная
                        скидка
                        указано 5%, система сделает скидку 15%</span>
                    </v-tooltip>

                    <v-text-field
                      v-model="participant.discount_text"
                      outlined
                      hide-details
                      label="Текст скидки"
                      class="v-label_small mt-5"
                      required
                    ></v-text-field>
                    <v-switch
                      v-model="participant.no_count"
                      label="Скрыть участника из всех списков"
                    ></v-switch>

                    <v-divider class="mt-10"></v-divider>
                  </v-card-text>
                </div>
                <v-btn
                  v-if="room.participants.length < room.capacity"
                  x-small
                  class="small-btn"
                  color="primary"
                  @click="addPeople(room.participants)"
                >
                  Добавить участника
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="mb-12"
        xs="12"
        sm="12"
        md="12"
        lg="5"
      >
        <v-card>
          <v-card-title
            class="pt-7"
            style="font-weigth: bold"
          >
            Промокод и скидка
          </v-card-title>
          <v-card-text>
            <v-divider class="mb-5"></v-divider>
          </v-card-text>

          <v-card-text v-if="reservation_object.reservation.promo_code">
            <div class="d-flex justify-space-between">
              <span>{{ reservation_object.reservation.promo_code.name }}: скидка {{
                reservation_object.reservation.promo_code.discount }}</span>
              <span @click="promoCodeDelete()">Удалить</span>
            </div>
          </v-card-text>

          <v-card-text v-else>
            <div class="d-flex justify-space-between align-center">
              <v-text-field
                v-model="promo_code_add"
                outlined
                hide-details
                label="Промокод"
                class="mr-5"
              >
              </v-text-field>
              <v-btn
                color="primary"
                @click="promoCodeAdd()"
              >
                Добавить
              </v-btn>
            </div>
          </v-card-text>

          <v-card-text>
            <v-divider class="mt-5 mb-5"></v-divider>
          </v-card-text>

          <v-card-text>
            <v-text-field
              v-model="reservation_object.additional_discount"
              type="number"
              :rules="[v => !!v || 'Скидка не может быть пустой', v => v < 96 || 'Скидка не может быть больше 95%']"
              outlined
              label="Дополнительная скидка бронирования"
              required
            >
            </v-text-field>
          </v-card-text>
        </v-card>
        <v-btn
          class="mt-5"
          color="success ml-5"
          @click="updateReservation()"
        >
          Сохранить
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="new_reservation_popup"
      width="100%"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Выберете номер
        </v-card-title>
        <template>
          <v-tabs
            v-model="room_tabs"
            align-with-title
          >
            <v-tab
              v-for="packages in free_event_rooms.packages"
              :key="packages.id"
            >
              {{ packages.packageName }}
            </v-tab>
          </v-tabs>
        </template>

        <v-tabs-items v-model="room_tabs">
          <v-tab-item
            v-for="item in free_event_rooms.packages"
            :key="item.id"
          >
            <v-simple-table>
              <template v-if="free_event_rooms">
                <thead>
                  <tr>
                    <th class="text-left">
                      Id
                    </th>
                    <th>Номер</th>
                    <th>Тип номера</th>
                    <th>Пол номера</th>
                    <th>Свободных мест</th>
                    <th>Действие</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="suit in item.suits"
                    :key="suit.id"
                  >
                    <td>{{ suit.id }}</td>
                    <td>{{ suit.name }} - {{ suit.room_number }}</td>
                    <td>{{ suit.order_room_type_name }}</td>
                    <td>{{ suit.gender_room_type_name }}</td>
                    <td>{{ suit.free_places }}</td>
                    <td>
                      <v-btn
                        x-small
                        color="success"
                        @click="changeRoom(suit.id)"
                      >
                        Выбрать
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import Autocomplete from '@/components/Autocomplete.vue'
import AddRoomModal from '@/views/events/AddRoomModal.vue'
import {
  mdiHelpCircle,
} from '@mdi/js'
import DatePicker from 'vue2-datepicker'
import { mapActions } from 'vuex'

export default {
  components: { AddRoomModal, Autocomplete, DatePicker },
  data() {
    return {
      users_list: [],
      search_user: null,
      select_user: null,
      free_event_rooms: [],
      new_reservation_popup: false,
      reservation_object: Array,
      old_room_id: null,
      old_reservation_room_id: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      date_arrival_modal: false,
      promo_code_add: null,
      room_tabs: null,
      headers_rooms: [{ text: 'Номер', value: 'name', align: 'start' }],
      icons: {
        mdiHelpCircle,
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
    )
  },
  async mounted() {
    // await this.getUsers()
    await this.getFrontReservation()
  },
  methods: {
    async getFrontReservation() {
      try {
        Api.getReservation({
          reservation_uuid: this.$route.params.reservation_id,
        })
          .then(
            (event => {
              this.$set(this, 'reservation_object', event)
              this.$set(this, 'select_user', event.user_id)
            }),
          )
          .catch(error => {
            if (error.response.status === 400) {
              this.$router.push({
                path: `/events/${this.$route.params.id}`,
                replace: true,
              })
            } else {
              // Handle else
            }
          })
      } catch { }
    },
    async getUsers() {
      try {
        Api.getUsers().then(
          (event => {
            this.$set(this, 'users_list', event)
          }),
        )
      } catch { }
    },
    ...mapActions(['addReservationData']),
    async AddReservation(event_room_id) {
      try {
        Api.AddReservation({
          event_room_id,
          reservation_uuid: this.reservation_object.uuid,
        }).then(
          (event => {
            if (event.reservation_uuid) {
              this.getFrontReservation()
            }
          }),
        )
      } catch (error) {
        this.loader = false
      }
    },
    async selectChangeRoom(room_id, reservation_room_id) {
      console.log(room_id, reservation_room_id)
      Api.getFreeEventRooms({
        event_id: parseInt(this.$route.params.id),
      }).then(
        (event => {
          this.$set(this, 'free_event_rooms', event)
          this.$set(this, 'new_reservation_popup', true)
          this.$set(this, 'old_room_id', room_id)
          this.$set(this, 'old_reservation_room_id', reservation_room_id)
        }),
      )
    },
    async changeRoom(new_room_id) {
      this.$set(this, 'new_reservation_popup', false)
      Api.changeRoomAdmin({
        reservation_uuid: this.reservation_object.reservation_uuid,
        room_id: parseInt(this.old_room_id),
        reservation_room_id: parseInt(this.old_reservation_room_id),
        new_room_id: parseInt(new_room_id),
      }).then(
        (event => {
          this.$set(this, 'old_room_id', null)
          this.$set(this, 'old_reservation_room_id', null)
          this.getFrontReservation()
        }),
      )
    },
    async updateReservation() {
      try {
        Api.updateReservation(this.reservation_object).then(
          (event => {
            this.$router.push({
              path: `/events/${this.$route.params.id}/${this.$route.params.reservation_id}`,
              replace: true,
            })

            // this.checkInvoice()
          }),
        )
      } catch { }
    },
    async checkInvoice() {
      try {
        Api.checkInvoice({
          reservation_uuid: this.reservation_object.reservation_uuid,
        }).then(
          (event => {
            this.$router.push({
              path: `/events/${this.$route.params.id}/${this.$route.params.reservation_id}`,
              replace: true,
            })
          }),
        )
      } catch { }
    },
    async deleteRoom(reservation_uuid, event_room_id) {
      try {
        Api.deleteRoom({
          reservation_uuid,
          event_room_id,
        })
          .then(
            (event => {
              this.getFrontReservation()
            }),
          )
          .catch(error => {
            if (error.response.status === 400) {
              this.$router.push({
                path: `/events/${this.$route.params.id}`,
                replace: true,
              })
            } else {
              // Handle else
            }
          })
      } catch (error) {
        console.log(error)
      }
    },
    deletePeople(array, index) {
      // console.log(participant, index)
      array.splice(index, 1)
    },
    addPeople(array) {
      array.push({
        age: '',
        childAge: null,
        gender: '',
        food: '',
        insurance: false,
        show_in_list: true,
        no_count: false,
        name: '',
        participantType: '',
        phone: '',
        start: array[0] ? array[0].start : null,
        end: array[0] ? array[0].end : null,
      })
    },
    promoCodeAdd() {
      try {
        Api.promoCodeAdd({
          event_id: this.reservation_object.reservation.event_id,
          promo_code: this.promo_code_add,
          user_reservation_uuid: this.reservation_object.reservation_uuid,
        }).then(
          (event => {
            this.getFrontReservation()
          }),
        )
      } catch { }
    },
    promoCodeDelete() {
      try {
        Api.promoCodeDelete({
          event_id: this.reservation_object.reservation.event_id,
          promo_code: this.reservation_object.reservation.promo_code.name,
          user_reservation_uuid: this.reservation_object.reservation_uuid,
        }).then(
          (event => {
            location.reload()
          }),
        )
      } catch { }
    },
    updateSelected(data) {
      this.reservation_object.user_id = data.id
    },
  },
  watch: {
    select_user(val) {
      this.reservation_object.user_id = val
    },
  },
}
</script>
<style lang="scss" scoped></style>
