<template>
  <div class="offer">
    <v-card
      class="mx-auto my-12 pa-5"
      max-width="500"
    >
      <my-loader :loader="loader"></my-loader>
      <div
        align="center"
        class="mt-5 mb-5"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="184.51"
          height="27"
          viewBox="0 0 184.51 27"
        ><g
          id="logo"
          transform="translate(-1.49)"
        ><g id="KhimkiBC"><g
          id="Group_57"
          data-name="Group 57"
        ><path
          id="Path_15"
          data-name="Path 15"
          d="M6.08,14.05,4.39,15.81V19H1.49V6.4h2.9v5.89L10,6.4h3.24L8,12l5.53,7h-3.4Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_16"
          data-name="Path 16"
          d="M23.41,10.25a4.28,4.28,0,0,1,1.11,3.21V19H21.71V13.89a2.51,2.51,0,0,0-.51-1.72,1.83,1.83,0,0,0-1.45-.57,2.24,2.24,0,0,0-1.7.66,2.72,2.72,0,0,0-.63,2V19h-2.8V5.64h2.8v4.68a3.92,3.92,0,0,1,1.35-.85,4.87,4.87,0,0,1,1.73-.3A4,4,0,0,1,23.41,10.25Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_17"
          data-name="Path 17"
          d="M27.2,7.52a1.53,1.53,0,0,1,0-2.24,1.77,1.77,0,0,1,1.26-.45,1.84,1.84,0,0,1,1.26.44,1.37,1.37,0,0,1,.48,1.08,1.55,1.55,0,0,1-.48,1.16A1.81,1.81,0,0,1,28.46,8a1.77,1.77,0,0,1-1.26-.48Zm-.15,1.8h2.81V19H27.05Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_18"
          data-name="Path 18"
          d="M47.76,10.24a4.36,4.36,0,0,1,1.07,3.22V19H46V13.89a2.61,2.61,0,0,0-.48-1.72,1.68,1.68,0,0,0-1.36-.57,2,2,0,0,0-1.57.64,2.78,2.78,0,0,0-.57,1.9V19H39.24V13.89c0-1.53-.61-2.29-1.84-2.29a2,2,0,0,0-1.54.64,2.73,2.73,0,0,0-.58,1.9V19H32.47V9.32h2.68v1.11a3.41,3.41,0,0,1,1.33-.93,4.36,4.36,0,0,1,1.72-.33,4.15,4.15,0,0,1,1.85.41,3.23,3.23,0,0,1,1.33,1.18,3.92,3.92,0,0,1,1.5-1.17,4.7,4.7,0,0,1,2-.42,3.9,3.9,0,0,1,2.88,1.07Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_19"
          data-name="Path 19"
          d="M55.51,15.2l-1.35,1.33V19H51.35V5.64h2.81V13.2l4.11-3.88h3.34l-4,4.1L62,19H58.6Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_20"
          data-name="Path 20"
          d="M63.31,7.52a1.53,1.53,0,0,1,0-2.24,1.77,1.77,0,0,1,1.26-.45,1.84,1.84,0,0,1,1.26.44,1.37,1.37,0,0,1,.48,1.08,1.55,1.55,0,0,1-.48,1.16A1.81,1.81,0,0,1,64.57,8a1.77,1.77,0,0,1-1.26-.48Zm-.15,1.8H66V19H63.16Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_21"
          data-name="Path 21"
          d="M79.72,13.59a3.13,3.13,0,0,1,.61,2,2.93,2.93,0,0,1-1.28,2.55,6.63,6.63,0,0,1-3.76.89H68.78V6.4h6.15a6,6,0,0,1,3.54.88,2.8,2.8,0,0,1,1.23,2.4,3,3,0,0,1-.44,1.63A3.08,3.08,0,0,1,78,12.45a3.36,3.36,0,0,1,1.72,1.14Zm-8-5v3h2.89a2.94,2.94,0,0,0,1.64-.38A1.343,1.343,0,0,0,76.21,9a3,3,0,0,0-1.64-.36Zm5.13,7.83a1.32,1.32,0,0,0,.59-1.17c0-1-.78-1.57-2.32-1.57h-3.4V16.8h3.4a3.22,3.22,0,0,0,1.69-.37Z"
          fill="#2e3b4d"
        ></path><path
          id="Path_22"
          data-name="Path 22"
          d="M85.09,18.38a6.26,6.26,0,0,1-2.44-2.32,6.35,6.35,0,0,1-.89-3.36,6.35,6.35,0,0,1,.89-3.36A6.26,6.26,0,0,1,85.09,7a7.28,7.28,0,0,1,3.51-.84,7.44,7.44,0,0,1,3,.58A5.9,5.9,0,0,1,93.82,8.4l-1.87,1.72a4,4,0,0,0-3.17-1.47,4.23,4.23,0,0,0-2.09.51,3.64,3.64,0,0,0-1.43,1.43,4.23,4.23,0,0,0-.51,2.09,4.23,4.23,0,0,0,.51,2.09,3.64,3.64,0,0,0,1.43,1.43,4.23,4.23,0,0,0,2.09.51,4,4,0,0,0,3.17-1.49L93.78,17a5.78,5.78,0,0,1-2.23,1.67,7.49,7.49,0,0,1-3,.58,7.23,7.23,0,0,1-3.46-.87Z"
          fill="#2e3b4d"
        ></path></g></g><rect
          id="Rectangle_12"
          data-name="Rectangle 12"
          width="86"
          height="27"
          rx="4"
          transform="translate(100)"
          fill="#0069dd"
        ></rect><g id="events"><g
          id="Group_58"
          data-name="Group 58"
        ><path
          id="Path_23"
          data-name="Path 23"
          d="M121.36,17v1h-6.1V9.6h5.93v1h-4.73v2.58h4.21v1h-4.21V17Z"
          fill="#fff"
        ></path><path
          id="Path_24"
          data-name="Path 24"
          d="M132.4,9.6,128.71,18h-1.19l-3.69-8.4h1.29l3,6.9,3-6.9Z"
          fill="#fff"
        ></path><path
          id="Path_25"
          data-name="Path 25"
          d="M141.48,17v1h-6.1V9.6h5.93v1h-4.73v2.58h4.22v1h-4.22V17Z"
          fill="#fff"
        ></path><path
          id="Path_26"
          data-name="Path 26"
          d="M152.57,9.6V18h-1l-5-6.26V18h-1.2V9.6h1l5,6.26V9.6Z"
          fill="#fff"
        ></path><path
          id="Path_27"
          data-name="Path 27"
          d="M158.68,10.64H155.8v-1h6.94v1h-2.88V18h-1.18Z"
          fill="#fff"
        ></path><path
          id="Path_28"
          data-name="Path 28"
          d="M166.48,17.8a3.61,3.61,0,0,1-1.38-.8l.44-.94a3.65,3.65,0,0,0,1.23.71,4.5,4.5,0,0,0,1.54.27,2.72,2.72,0,0,0,1.56-.36,1.07,1.07,0,0,0,.52-.94,1,1,0,0,0-.28-.7,2,2,0,0,0-.7-.42c-.28-.09-.67-.2-1.17-.32a10.71,10.71,0,0,1-1.53-.46,2.49,2.49,0,0,1-1-.7,1.89,1.89,0,0,1-.41-1.28,2.22,2.22,0,0,1,.35-1.21,2.42,2.42,0,0,1,1.08-.86,4.27,4.27,0,0,1,1.79-.33,5.59,5.59,0,0,1,1.46.2,3.8,3.8,0,0,1,1.24.55l-.4,1a4,4,0,0,0-1.13-.51,4.06,4.06,0,0,0-1.17-.18,2.57,2.57,0,0,0-1.53.38,1.12,1.12,0,0,0-.51,1,.93.93,0,0,0,.28.7,2,2,0,0,0,.72.42c.28.1.67.2,1.16.32a11.66,11.66,0,0,1,1.52.45,2.64,2.64,0,0,1,1,.7,1.81,1.81,0,0,1,.41,1.26,2.12,2.12,0,0,1-.36,1.2,2.35,2.35,0,0,1-1.09.87,4.56,4.56,0,0,1-1.8.32,5.66,5.66,0,0,1-1.84-.34Z"
          fill="#fff"
        ></path></g></g></g></svg>
      </div>
      <div v-if="offer">
        <v-card-text>
          <p>
            Здравствуйте, {{ offer.trainer_name }}!<br>
            Вам направлено предложение принять участие на мероприятии в качестве тренера / члена команды организаторов.
          </p>

          <p>
            Ниже представлены подробные данные и условия сотрудничества. <br>
            Название мероприятия: <b>{{ offer.event_title }}</b> <br>
            Даты: <b>{{ offer.start_date }} - {{ offer.end_date }}</b> <br>
            Расчетное количество рабочих дней: <b>{{ offer.days_amount }}</b> <br>
          </p>
          <p>
            Согласованный функционал: <br>
            {{ offer.agreed_functionality }}
          </p>
          <p>
            Условия оплаты: <br>
            {{ offer.salary_description }}
          </p>
        </v-card-text>

        <v-divider></v-divider>
        <div v-if="!success">
          <v-card-actions>
            <v-btn
              color="success"
              class=" mt-5"
              @click="successOffer(true)"
            >
              Принять
            </v-btn>
            <v-btn
              color="error"
              class=" mt-5"
              @click="successOffer()"
            >
              Отклонить
            </v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text>
            <div
              v-if="offer.accepted"
              class="pt-5"
            >
              <v-chip
                style="background-color: green;color: #fff;"
              >
                Предложение принято
              </v-chip>
            </div>
            <div
              v-else
              class="pt-5"
            >
              <v-chip
                style="background-color: red;color: #fff;"
              >
                Предложение отклонено
              </v-chip>
            </div>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  data() {
    return {
      loader: false,
      offer: null,
      success: false,
    }
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getTrainerOffer()
  },
  methods: {
    openOffer() {},
    getTrainerOffer() {
      this.loader = true
      const params = new URLSearchParams([['uuid', this.$route.params.uuid]])
      Api.getTrainerOffer({ params }).then(
        (offer => {
          this.loader = false
          this.offer = offer
          this.success = offer.answered
        }),
      )
    },
    successOffer(answer = false) {
      let msg = 'отклонить'
      if (answer) {
        msg = 'принять'
      }
      if (confirm(`Вы уверены что хотите ${msg} предложение?`)) {
        this.loader = true
        try {
          Api.trainerOfferAnswer({
            uuid: this.offer.uuid,
            response: answer,
          }).then(
            (async event => {
              if (event.message) {
                this.success = true
                await this.getTrainerOffer()
                this.loader = false
              }
            }),
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
