<template>
  <div>
    <v-btn
      :to="'/events/' + $route.params.id"
      class="ma-0 mb-5 mr-5"
      transparent
    >
      Назад
    </v-btn>
    <v-btn
      class="ma-0 mb-5 error"
      @click="deleteTrainerOffer()"
    >
      Удалить
    </v-btn>
    <h1 class="mb-5">
      Оффер для: {{ offer.trainer_name }}
    </h1>

    <v-card id="account-setting-card">
      <v-card-text>
        <p>
          Здравствуйте, {{ offer.trainer_name }}!<br>
          Вам направлено предложение принять участие на мероприятии в качестве тренера / члена команды организаторов.
        </p>

        <p>
          Ниже представлены подробные данные и условия сотрудничества. <br>
          Название мероприятия: <b>{{ offer.event_title }}</b> <br>
          Даты: <b>{{ offer.start_date }} - {{ offer.end_date }}</b> <br>
          Расчетное количество рабочих дней: <b>{{ offer.days_amount }}</b> <br>
        </p>
        <p>
          Согласованный функционал: <br>
          {{ offer.agreed_functionality }}
        </p>
        <p>
          Условия оплаты: <br>
          {{ offer.salary_description }}
        </p>
      </v-card-text>
      <v-card-title>Статус ответа</v-card-title>
      <v-card-text>
        <template v-if="offer.answered">
          <template v-if="offer.accepted">
            Согласовано
          </template>
          <template v-else>
            Отказ
          </template>
        </template>
        <template v-else>
          Ответ не получен
        </template>
      </v-card-text>
      <v-card-title>Ссылка</v-card-title>
      <v-card-text>
        https://admin.khimkibc.ru/offer/{{ offer.uuid }}/
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  data() {
    return {
      offer: null,
    }
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getTrainerOffer()
  },
  methods: {
    openOffer() {},
    getTrainerOffer() {
      const params = new URLSearchParams([['uuid', this.$route.params.uuid]])
      Api.getTrainerOffer({ params }).then(
        (offer => {
          this.offer = offer
        }),
      )
    },
    deleteTrainerOffer() {
      const params = new URLSearchParams([['uuid', this.$route.params.uuid]])
      Api.deleteTrainerOfferAnswer({ params }).then(
        (response => {
          this.$router.push({
            path: `/events/${this.$route.params.id}`,
            replace: true,
          })
        }),
      )
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
