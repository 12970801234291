<template>
  <div>
    <div class="align-center"></div>

    <div v-if="feedback_list">
      <p class="text-2xl mb-6">
        Обратная связь
      </p>

      <v-card>
        <v-data-table
          :headers="headers"
          :items="feedback_list"
          :loading="loading"
          :server-items-length="30"
          loading-text="Загрузка данных"
          class="elevation-1"
          :hide-default-footer="true"
          :item-class="rowClass"
        >
          <template #[`item.id`]="{ item }">
            <router-link :to="'/feedback/' + item.uuid">
              <span>{{ item.id }}</span>
            </router-link>
          </template>

          <template v-slot:[`item.training_time`]="{ item }">
            {{ item.training_time | formatDate }}
          </template>

          <template v-slot:[`item.answered_at`]="{ item }">
            {{ item.answered_at }}
          </template>
        </v-data-table>
        <v-pagination
          v-model="current_page"
          :length="total_page"
          @input="getFeedbackList"
        ></v-pagination>
      </v-card>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'

export default {
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    formatDate(value) {
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      const hour = date.getHours()
      const minute = date.getMinutes()
      return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} ${hour}:${minute === 0 ? '00' : minute}`
    },
  },
  data() {
    return {
      loading: false,
      totalFeedback: null,
      total_page: null,
      current_page: 1,
      headers: [
        { text: 'Номер', value: 'id', align: 'start' },
        { text: 'Клиент', value: 'client_name' },
        { text: 'Телефон', value: 'client_phone' },
        { text: 'Тренер', value: 'staff_name' },
        { text: 'Дата тренировки', value: 'training_time' },
        { text: 'Дата ответа', value: 'answered_at' },
      ],
      options: {},
      errorMessages: '',
      feedback_list: [],
      status: {
        true: 'выдана',
        false: 'не выдана',
      },
      statusColor: {
        false: 'info',
        true: 'success',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getFeedbackList()
      },
      deep: true,
    },
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getFeedbackList()
  },
  methods: {
    getFeedbackList() {
      this.loading = true
      const params = new URLSearchParams([['page', this.current_page]])
      try {
        Api.getUsersFeedback({ params }).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'feedback_list', event.results)
            this.$set(this, 'totalFeedback', event.results.length)
            this.$set(this, 'current_page', event.current_page)
            this.$set(this, 'total_page', event.num_pages)
          }),
        )
      } catch (error) {
        console.log(error)
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    updateSelected(data) {
      this.selected_user = data
    },
    rowClass(item) {
      let rowClass = 'read'
      if (!item.read) {
        rowClass = 'unread'
      }

      return rowClass
    },
  },
}
</script>

<style>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}
.unread{
  font-weight: bold;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
