import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import EventList from '@/views/events/EventsList'
import SingleEvent from '@/views/events/SingleEvent'
import ReservationPage from '@/views/events/ReservationPage'
import ReservationPageEdit from '@/views/events/ReservationPageEdit'
import ReservationPageAdd from '@/views/events/ReservationPageAdd'
import PlaceList from '@/views/places/PlaceList'
import ClientsList from '@/views/events/ClientsList'
import BonusTransaction from '@/views/bonus/BonusTransaction'
import Coupon from '@/views/coupon/Coupon'
// import Cert from '@/views/certs/Cert'
import CertNew from '@/views/certs/CertNew'
import ClubPlayer from '@/views/club_player/ClubPlayer'
import ClubPlayerClients from '@/views/club_player/ClubPlayerClients'
import EventPackagesList from '@/views/events/EventPackagesList'
import EventPackage from '@/views/events/EventPackage'
import Promocodes from '@/views/promocodes/Promocodes.vue'
import PromocodesPage from '@/views/promocodes/PromocodesPage.vue'
import TaxDeduction from '@/views/tax_deduction/TaxDeduction.vue'
import TaxDeductionPage from '@/views/tax_deduction/TaxDeductionPage.vue'
import Acquiring from '@/views/acquiring/Acquiring.vue'
import Sales from '@/views/sales/Sales.vue'
import SingleOffer from '@/views/events/SingleOffer.vue'
import SingleOfferAll from '@/views/events/SingleOfferAll.vue'
import FeedBack from '@/views/feedback/FeedBack.vue'
import FeedBackOne from '@/views/feedback/FeedBackOne.vue'

import Qrcode_lockers from '@/views/qrcode_lockers/Qrcode_lockers.vue'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'KhimkiBC Group'

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: { requiresAuth: true, title: 'Главная', permissions: 'dashboard', },
  },
  {
    path: '/offer/:uuid',
    component: SingleOfferAll,
    name: 'Оффер',
    meta: {
      layout: 'blank',
      title: 'Оффер',
      requiresAuth: false,
    },
  },
  {
    path: '/events',
    name: 'Сборы',
    component: EventList,
    meta: {
      requiresAuth: true,
      title: 'Список мероприятий',
      permissions: 'events',
    },
  },
  {
    path: '/clients',
    name: 'Клиенты',
    component: ClientsList,
    meta: {
      requiresAuth: true, title: 'Список клиентов', permissions: 'clients',
    },
  },
  {
    path: '/bonustransactions',
    name: 'Бонусы',
    component: BonusTransaction,
    meta: {
      requiresAuth: true, title: 'Операции с бонусами', permissions: 'bonuspoints',
    },
  },
  {
    path: '/events/:id/new',
    name: 'Новое бронирование',
    component: ReservationPageAdd,
    meta: {
      requiresAuth: true, title: 'Новое бронирование', permissions: 'events',
    },
  },
  {
    path: '/events/:id',
    name: 'Страница мероприятия',
    component: SingleEvent,
    meta: {
      requiresAuth: true, title: 'Мероприятие', permissions: 'events',
    },
  },
  {
    path: '/events/:id/offer/:uuid',
    name: 'Страница оффера',
    component: SingleOffer,
    meta: {
      requiresAuth: true, title: 'Оффер', permissions: 'events',
    },
  },
  {
    path: '/events/:id/packages',
    name: 'Пакеты мероприятия',
    component: EventPackagesList,
    meta: {
      requiresAuth: true, title: 'Пакеты мероприятия', permissions: 'events',
    },
  },
  {
    path: '/events/:id/packages/:package_id',
    name: 'Пакет мероприятия',
    component: EventPackage,
    meta: {
      requiresAuth: true, title: 'Пакет мероприяти', permissions: 'events',
    },
  },
  {
    path: '/events/:id/:reservation_id',
    name: 'Страница бронирования',
    component: ReservationPage,
    meta: {
      requiresAuth: true, title: 'Бронирование', permissions: 'events',
    },
  },
  {
    path: '/events/:id/:reservation_id/edit',
    name: 'Редактировать бронирование',
    component: ReservationPageEdit,
    meta: {
      requiresAuth: true, title: 'Редактировать бронирование', permissions: 'events',
    },
  },
  {
    path: '/places',
    name: 'Места',
    component: PlaceList,
    meta: {
      requiresAuth: true, title: 'Места', permissions: 'events',
    },
  },
  {
    path: '/coupons',
    name: 'Купоны',
    component: Coupon,
    meta: {
      requiresAuth: true, title: 'Купоны', permissions: 'coupons',
    },
  },
  {
    path: '/certs',
    name: 'Сертификаты',
    component: CertNew,
    meta: {
      requiresAuth: true, title: 'Сертификаты', permissions: 'certs',
    },
  },
  {
    path: '/club_player',
    name: 'Клубный игрок',
    component: ClubPlayer,
    meta: {
      requiresAuth: true, title: 'Клубный игрок', permissions: 'club_player',
    },
  },
  {
    path: '/club_player/clients',
    name: 'Участники программы клубный игрок',
    component: ClubPlayerClients,
    meta: {
      requiresAuth: true, title: 'Участники программы клубный игрок', permissions: 'club_player',
    },
  },
  { // Promocodes
    path: '/promocodes',
    name: 'Промокоды',
    component: Promocodes,
    meta: {
      requiresAuth: true, title: 'Промокоды', permissions: 'promocodes',
    },
  },
  
  {
    path: '/tax_deduction/:id',
    name: 'Справка для налогового вычета',
    component: TaxDeductionPage,
    meta: {
      requiresAuth: true, title: 'Налоговый вычет', permissions: 'tax_deduction',
    },
  },

  { // Sales
    path: '/sales',
    name: 'Акции',
    component: Sales,
    meta: {
      requiresAuth: true, title: 'Акции', permissions: 'sales',
    },
  },

  {
    path: '/promocodes/:id',
    name: 'Отдельный промокод',
    component: PromocodesPage,
    meta: {
      requiresAuth: true, title: 'Промокоды', permissions: 'promocodes',
    },
  },
  { // TaxDeduction
    path: '/tax_deduction',
    name: 'Налоговый вычет',
    component: TaxDeduction,
    meta: {
      requiresAuth: true, title: 'Налоговый вычет', permissions: 'tax_deduction',
    },
  },
  { // Acquiring
    path: '/acquiring',
    name: 'Эквайринг',
    component: Acquiring,
    meta: {
      requiresAuth: true, title: 'Эквайринг', permissions: 'acquiring',
    },
  },

  { // Обратная связь
    path: '/feedback',
    name: 'Обратная связь',
    component: FeedBack,
    meta: {
      requiresAuth: true, title: 'Обратная связь', permissions: 'feedback',
    },
  },
  { //
    path: '/feedback/:uuid',
    name: 'Обратная связь клиента',
    component: FeedBackOne,
    meta: {
      requiresAuth: true, title: 'Обратная связь клиента', permissions: 'feedback',
    },
  },

  { // Qrcode_lockers
    path: '/qrcode_lockers',
    name: 'Шкафчики ВДНХ',
    component: Qrcode_lockers,
    meta: {
      requiresAuth: true, title: 'Шкафчики ВДНХ', permissions: 'qrcode_lockers',
    },
  },

  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Страница входа',
      guest: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      title: 'Страница не найдена',
    },
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('@/views/Noauth.vue'),
    meta: {
      layout: 'blank',
      title: 'Ошибка, вы не авторизованы',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {

  await store.dispatch('getAccount')
  const user = store.getters.user;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      if (
        user.permissions.some(p => p.project === to.meta.permissions)
      ) {
        next()
        return
      } else {
        router.push({
          name: 'Unauthorized'
        })
        return
      }
    } else {

    }
    next('/pages/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    next()
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + ' – ' + DEFAULT_TITLE || DEFAULT_TITLE
  })
})

export default router
