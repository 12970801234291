<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <p class="text-2xl mb-6">
      Доступы шкафчикам Space ВДНХ
    </p>

    <div>
      <v-text-field
        v-model="phoneNumber"
        label="Введите номер телефона"
        outlined
        placeholder="+79991234567"
        :rules="phoneRules"
        @keyup.enter="searchClient"
      ></v-text-field>

      <div class="d-flex">
        <v-btn
          color="primary"
          class="mr-3"
          @click="searchClient"
        >
          Найти
        </v-btn>

        <v-btn
          color="primary"
          @click="openModal"
        >
          Выдать QR-код в ручном режиме
        </v-btn>
      </div>
    </div>

    <v-divider class="mt-7"></v-divider>

    <template
      v-if="client"
    >
      <div class="mt-5">
        <b>Найден клиент:</b> <br>
        {{ client_name }} <br>
        {{ client_email }} <br>
        {{ client_phone }} <br>

        <v-btn
          color="primary"
          small
          class="mt-5"
          @click="manualQRCode"
        >
          Получить Qr-code
        </v-btn>
      </div>

      <div v-if="qrCode">
        <v-img
          class="mt-5"
          width="400"
          :src="qrCode"
        />
        <a
          :href="qrCode"
          target="_blank"
        >открыть в новом окне</a>
      </div>
    </template>

    <v-dialog
      v-model="modal"
      max-width="600px"
      persistent="true"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Введите данные клиента</span>
        </v-card-title>

        <v-divider class="mx-4"></v-divider>

        <template>
          <v-card-text class="mt-10">
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-text-field
                v-model="manualName"
                label="Имя"
                outlined
                :rules="nameRules"
              ></v-text-field>

              <v-text-field
                v-model="manualPhone"
                label="Телефон"
                outlined
                :rules="phoneRules"
              ></v-text-field>
            </v-form>
          </v-card-text>
        </template>

        <div
          v-if="qrCode"
          class="text-center"
        >
          <v-img
            class="mt-5"
            width="400"
            :src="qrCode"
          />
          <a
            :href="qrCode"
            target="_blank"
          >открыть в новом окне</a>
        </div>
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            @click="manualQRCodeByForm"
          >
            Выдать
          </v-btn>
          <v-btn
            color="error"
            @click="closeModal"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },

  data() {
    return {
      loader: false,
      phoneNumber: '',
      client: null,
      client_name: null,
      client_phone: null,
      client_email: null,
      client_id: null,
      notFound: false,
      modal: false,
      manualName: '',
      manualPhone: '',
      qrCode: null,
      qrCodehref: null,
      valid: false,
      nameRules: [v => !!v || 'Имя обязательно'],
      phoneRules: [v => !!v || 'Телефон обязателен'],
    }
  },

  methods: {

    async searchClient() {
      this.loader = true
      try {
        const response = await Api.searchClient({ phone: this.phoneNumber })
        this.$set(this, 'loading', false)

        if (response.id) {
          this.client = true
          this.client_email = response.email
          this.client_id = response.id
          this.client_name = response.name
          this.client_company_id = response.company_id
          this.client_phone = this.phoneNumber
          this.$set(this, 'loader', false)
        } else {
          this.client = null
        }
      } catch (error) {
        this.$set(this, 'loader', false)
        console.error(error)
      }
    },

    openModal() {
      this.modal = true
      this.qrCode = null
      this.client = null
      this.client_email = null
      this.client_id = null
      this.client_name = null
      this.client_company_id = null
      this.client_phone = null
    },

    closeModal() {
      this.modal = false
      this.manualName = ''
      this.manualPhone = ''
      this.qrCode = null
      this.qrCodehref = null
    },

    async manualQRCode() {
      this.loader = true

      try {
        const response = await Api.manualQRCode({
          name: this.client_name,
          phone: this.client_phone,
        })
        this.qrCode = URL.createObjectURL(response)
      } catch (error) {
        console.error(error)
      } finally {
        this.$set(this, 'loader', false)
      }
    },

    async manualQRCodeByForm() {
      this.loading = true
      if (this.$refs.form.validate()) {
        try {
          const response = await Api.manualQRCode({
            name: this.manualName,
            phone: this.manualPhone,
          })
          this.qrCode = URL.createObjectURL(response)
        } catch (error) {
          console.error(error)
        } finally {
          this.$set(this, 'loader', false)
        }
      } else {
        this.$set(this, 'loader', false)
      }
    },

  },

}
</script>
