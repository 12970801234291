<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <div v-if="promocodes_list[0]">
      <p class="text-2xl mb-6">Список промокодов | Отдельный промокод № {{ $route.params.id }}</p>
      <div class="mb-5">
        <router-link to="/promocodes"> Назад к списку промокодов </router-link>
      </div>

      <v-card>
        <v-card-title class="mb-5">Промокод № {{ $route.params.id }} </v-card-title>
        <v-card-subtitle>О промокоде</v-card-subtitle>
        <v-card-text>
          <ul>
            <li>Id: {{ promocodes_list[0].id }}</li>
            <li>Промокод: {{ promocodes_list[0].name }}</li>
            <li>Скидка: {{ promocodes_list[0].discount }}</li>
            <li>Идентификатор события: {{ promocodes_list[0].event_id }}</li>
            <li>Название: {{ promocodes_list[0].event_name }}</li>
            <li>Срок: {{ promocodes_list[0].expired_date }}</li>
            <li>URL: {{ promocodes_list[0].url }}</li>
            <li>Использованно: {{ promocodes_list[0].used }}</li>
            <li>Клиент: {{ promocodes_list[0].users }}</li>
          </ul>
        </v-card-text>

        <tr v-for="(item, index) in tax_deduction_list[0].orders" :key="item.id"></tr>
      </v-card>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: { MyLoader },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
  },
  data() {
    return {
      errorMessages: '',
      active_project: [],
      promocodes_list: [],
      search: null,
    }
  },
  watch: {
    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  setup() {
    const loading = false
    const loader = false
    return {
      headers: [
        { text: 'ID', value: 'id', align: 'start' },
        { text: 'Промокод', value: 'name' },
        { text: 'Скидка', value: 'discount' },
        { text: 'Идентификатор события', value: 'event_id' },
        { text: 'Название', value: 'event_name' },
        { text: 'Срок', value: 'expired_date' },
        { text: 'URL', value: 'url' },
        { text: 'Использованно', value: 'used' },
        { text: 'Клиент', value: 'users' },
      ],
      valid: '',
      loader,
      loading,
    }
  },
  async mounted() {
    this.getPromocodes()
  },
  methods: {
    getPromocodes() {
      this.loading = true
      try {
        Api.getPromocodes({
          id: this.$route.params.id,
        }).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'promocodes_list', event)
            this.promocodes_list = event[0]
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },

    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style scoped>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}
</style>
