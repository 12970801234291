import axios from 'axios'
import authHeader from '@/api/auth-header'

export default {
  async getEvents() {
    const res = await axios.get('/get_all_events_admin/')
    return res.data
  },

  async getEventDataById(eventId) {
    const res = await axios.get(`/get_event_data_by_id?event_id=${eventId}`, {
      headers: authHeader(),
    })
    return res.data
  },

  async getReservationListByEventId(params) {
    const res = await axios.post('/get_events_by_id/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getReservationByUuid(params) {
    const res = await axios.post('/get_reservation_by_uuid/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getReservation(params) {
    const res = await axios.post('/get_reservation_admin/', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async updateReservation(params) {
    const res = await axios.post('/update_reservation_admin/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async checkInvoice(params) {
    const res = await axios.post('/check_invoice_reservation/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async deleteInvoice(params) {
    const res = await axios.post('/delete_invoice/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async checkPay(params) {
    const res = await axios.post('/check_pay/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getFreeEventRooms(params) {
    const res = await axios.post('/get_free_event_rooms/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async AddReservation(params) {
    const res = await axios.post('/add_reservation/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getRoomForEvents(params) {
    const res = await axios.post('/get_room_for_events/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getUsers() {
    const res = await axios.get('/get_users/', {
      headers: authHeader(),
    })
    return res.data
  },

  async createCashInvoice(params) {
    const res = await axios.post('/create_cash_invoice/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async deleteReservation(params) {
    const res = await axios.post('/delete_reservation/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async deleteRoom(params) {
    const res = await axios.post('/delete_room_in_reservation_admin/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async reservationSuccess(params) {
    const res = await axios.post('/reservation_success/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getPackagesByEventId(params) {
    const res = await axios.post('/get_packages_by_event_id/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getPackageById(params) {
    const res = await axios.post('/get_package_by_id/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async updatePackageById(params) {
    const res = await axios.post('/update_package_by_id/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getEventPeopleByDays(params) {
    const res = await axios.post('/get_event_people_by_days/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getEventPeopleList(params) {
    const res = await axios.post('/get_event_people/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async changeEventPeopleSportType(params) {
    const res = await axios.post('/change_event_people_sport_type/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async changeRoomAdmin(params) {
    const res = await axios.post('/change_room_admin/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getRoomListByEventIdAndRoomType(params) {
    const res = await axios.post('/get_room_list_by_event_id_and_room_type/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async resettleAdmin(params) {
    const res = await axios.post('/resettle_admin/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async confirmReservation(params) {
    const res = await axios.post('/confirm_reservation/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async synchEventsRacketcamp(params) {
    const res = await axios.post('/synch_events_racketcamp/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async addBonusTransaction(params) {
    const res = await axios.post('/create_bonuses_for_event/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getClients(params) {
    const res = await axios.post('/get_clients/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getBonusTransaction() {
    const res = await axios.get('/get_bonuses_transaction/')
    return res.data
  },

  async createBonusTransaction(params) {
    const res = await axios.post('/create_bonus_transaction/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async writeOffBonuses(params) {
    const res = await axios.post('/write_off_bonuses/', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async writeOffBonusesWithComment(params) {
    const res = await axios.post('/write_off_bonuses_with_comment/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  // PROMOCODE

  async promoCodeAdd(params) {
    const res = await axios.post('/promo_code/', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async promoCodeDelete(params) {
    const res = await axios.delete(
      '/promo_code/',
      { data: params },
      {
        headers: authHeader(),
      },
    )
    return res.data
  },

  // СЕРТИФИКАТЫ
  async getActiveProject() {
    const res = await axios.get('/certs/projects', {
      headers: authHeader(),
    })
    return res.data
  },
  async getCerts(params) {
    const res = await axios.post('/certs/get', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async createCert(params) {
    const res = await axios.post('/certs/create', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async cancelCert(params) {
    const res = await axios.post('/certs/cancel', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async archivedCert(params) {
    const res = await axios.post('/certs/archived', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async createBadmintonistWithoutBill(params) {
    const res = await axios.post('/certs/create_badmintonist_without_bill', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async createBadmintonistWithBill(params) {
    const res = await axios.post('/certs/create_badmintonist_with_bill', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async msRetailDemandData(params) {
    const res = await axios.post('/certs/ms_retail_demand_data', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async activateСertBadmintonist(params) {
    const res = await axios.post('/certs/activate_badmintonist', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async msDemandForCertTemp(params) {
    const res = await axios.post('/certs/ms_demand_for_cert_temp', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async checkCertOperation(params) {
    const res = await axios.post('/certs/check_cert_operation', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async createSpaceXbadmBill(params) {
    const res = await axios.post('/certs/create_space_xbadm_bill', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getCertHistory(params) {
    const res = await axios.post('/certs/get_cert_history', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async paperComment(params) {
    const res = await axios.post('/certs/paper_comment', params, {
      headers: authHeader(),
    })
    return res.data
  },

  // CLUB PLAYER
  async createSpaceXbadmNoBill(params) {
    const res = await axios.post('/certs/create_space_xbadm_no_bill', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async giftRequests() {
    const res = await axios.get('/club_player/gift_requests', {
      headers: authHeader(),
    })
    return res.data
  },
  async completeGiftRequest(params) {
    const res = await axios.post('/club_player/complete_gift_request', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async deleteGiftRequest(params) {
    const res = await axios.post('/club_player/delete_gift_request', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async getClubPlayerClients() {
    const res = await axios.get('/club_player/users_points', {
      headers: authHeader(),
    })
    return res.data
  },
  async getClubPlayerClientHistory(userPk) {
    const res = await axios.get(`/club_player/history?user_pk=${userPk}`, {
      headers: authHeader(),
    })
    return res.data
  },
  async ClubPlayerTopupPoints(params) {
    const res = await axios.post('/club_player/topup_points', params, {
      headers: authHeader(),
    })
    return res.data
  },

  //PROMOCODES

  async getPromocodes(params) {
    const res = await axios.post('/get_promocodes', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getAllEvents() {
    const res = await axios.get('/get_all_events/')
    return res.data
  },

  async createPromocodes(params) {
    const res = await axios.post('/create_promocode', params, {
      headers: authHeader(),
    })
    return res.data
  },

  //TAX DEDUCTION
  async getTaxDeduction(params) {
    const res = await axios.post('/sales/tax_deduction_certs', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async completeTaxDeduction(params) {
    const res = await axios.post('/sales/complete_tax_deduction', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async addManualOrder(params) {
    const res = await axios.post('/sales/add_manual_order', params, {
      headers: authHeader(),
    })
    return res.data
  },
  async updateTaxDeductionCert(params) {
    const res = await axios.post('/sales/update_tax_deduction_cert', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async uploadTaxDeductionFile(params) {
    const res = await axios.post('/sales/upload_tax_deduction_file', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async sendTrainerOffer(params) {
    const res = await axios.post('send_trainer_offer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getTrainerOffer(params) {
    const res = await axios.get('trainer_offer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async addTrainerOffer(params) {
    const res = await axios.post('trainer_offer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async editTrainerOffer(params) {
    const res = await axios.patch('trainer_offer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async trainerOfferAnswer(params) {
    const res = await axios.patch('trainer_offer_answer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async deleteTrainerOfferAnswer(params) {
    const res = await axios.delete('trainer_offer', params, {
      headers: authHeader(),
    })
    return res.data
  },

  // Обратная связь

  async getUsersFeedback(params) {
    const res = await axios.get('/reports/users_feedback/', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async getUsersFeedbackByUuid(uuid, params) {
    const res = await axios.get(`/reports/users_feedback/${uuid}/`, params, {
      headers: authHeader(),
    })
    return res.data
  },

  async deleteUserFeedback(uuid) {
    const res = await axios.delete(`/reports/users_feedback/${uuid}/`, {
      headers: authHeader(),
    })
    return res.data
  },

  // Шкафчики Space ВДНХ

  async searchClient(params) {
    const res = await axios.post('/yclients/search_client', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async generateQRCode(params) {
    const res = await axios.post('/userdata/locker_qr', params, {
      headers: authHeader(),
    })
    return res.data
  },

  async manualQRCode(params) {
    const res = await axios.post('/userdata/locker_qr_manual', params, {
      headers: authHeader(),
      responseType: 'blob',
    })
    return res.data
  },

}
