<template>
  <div v-if="events_list">
    <p class="text-2xl mb-6">Список сборов</p>
    <my-loader :loader="loader"></my-loader>
    <event :events="events_list"></event>
  </div>
</template>

<script>
import Api from '@/api/api'
import Event from './Event.vue'
import MyLoader from '@/components/MyLoader.vue'

export default {
  components: {
    Event,
    MyLoader,
  },
  data() {
    return {
      events_list: [],
      loader: false,
    }
  },
  mounted() {
    this.get_all_events()
  },
  methods: {
    async get_all_events() {
      this.loader = true
      try {
        Api.getEvents().then(event => {
          this.$set(this, 'events_list', event)
          this.loader = false
        })
      } catch {
        this.loader = false
        console.log('error')
      }
    },
  },
}
</script>
